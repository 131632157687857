// @js-import "@shared/vendor/jquery-1.9.1.min.js"
// @js-import "@shared/jquery-fn.js"
// @js-import "@shared/utils.js"
// @js-import "@shared/components.js"
// @js-import "@shared/components-utils.js"
// @js-import "@shared/screen-size.js"
// @js-import "@shared/CmsMenuUtils.js"
// @js-import "@shared/stringUtils.js"
// @js-import "@shared/axios-0.19.0.min.js"

ComponentsManager.register('header', function() {
    function stateManager() {
        return {
            FORCE_CHANGE: {},
            attributes: {},
            listeners: {},
            on: function on(name, callback, context) {
                var listeners = this.listeners[name];
                if (!listeners) {
                    this.listeners[name] = new Array();
                    listeners = this.listeners[name];
                }
                listeners.push({callback: callback, context: context});
            },
            get: function get(name) {
                var attribute = this.attributes[name];
                if (attribute) {
                    return attribute.value;
                }
                return null;
            },
            set: function set(name, value, setter, forceChange) {
                this._set([
                    {
                        name: name,
                        value: value
                    }
                ], setter, forceChange);
            },
            _set: function _set(sets, setter, forceChange) {
                var i,
                    l,
                    set,
                    attribute,
                    prevValue;
                var changedAttributes = [];
                for (i = 0; i < sets.length; i++) {
                    set = sets[i];
                    attribute = this.attributes[set.name];
                    if (!attribute) {
                        attribute = this.attributes[set.name] = {
                            value: null
                        };
                    }
                    prevValue = attribute.value;
                    if (forceChange === this.FORCE_CHANGE || prevValue !== set.value) {
                        attribute.value = set.value;
                        changedAttributes.push({name: set.name, value: attribute.value, prevValue: prevValue});
                    }
                }
                for (i = 0; i < changedAttributes.length; i++) {
                    var change = changedAttributes[i];
                    var listeners = this.listeners[change.name] || new Array();
                    for (l = 0; l < listeners.length; l++) {
                        var listener = listeners[l];
                        listener.callback.call(listener.context, change.value, change.prevValue, setter);
                    }
                }
            }
        };
    }

    /* State init */
    var headerState = new stateManager();

    const SEARCH_CONTENT = 'SEARCH_CONTENT',
          SEARCH_DATA = 'SEARCH_DATA',
          SEARCH_QUERY = 'SEARCH_QUERY';

    headerState.set(SEARCH_CONTENT, false);
    headerState.set(SEARCH_DATA, false);
    headerState.set(SEARCH_QUERY, '');

    /* Autocompleter */
    const defaultSegmentName = 'homepage';
    var validateEmptySpaces = null;

    var privateMethods = {
        state: {
            currentScroll: null,
            direction: '',
            prevDirection: 0,
            prevScroll: window.scrollY || document.scrollTop
        },
        stateClass: function() {
            return {
                isActive: '.isActive'
            };
        },
        updateState: function(key, value) {
            return Object.assign(this.state, {
                [`${key}`]: value
            });
        },
        getFromState: function(key) {
            return this.state[key];
        },
        classes: function() {
            return {
                isActive: 'isActive',
                isEmpty: 'isEmpty',
                isDirty: 'isDirty',
                resultAnchor: 'header__searchResultAnchor',
            };
        },
        fewClasses: function(classes) {
            return classes.join(" ");
        },
        autocompleter: {
            apiUrl: '/_api/ds/ingrwd-lds/autocompleter',
            size: 6,
            minQuery: 3,
            debunceDelay: 250
        },
        selectors: function() {
            return {
                layoutContainer: '.layout_container',
                headerSearchButton: '.js-header__search',
                headerSearchClose: '.header__searchClose',
                headerSearchBox: '.header__searchBox',
                headerMenuBox: '.header__menuBox',
                headerMenuClose: '.header__menuClose',
                headerMenuButton: '.js-header__hamburger',
                headerSearchClear: '.header__searchClear',
                headerSearchControlInput: '.header__searchControlInput',
                headerSearchForm: '.header__searchForm',
                headerSearch: '.header__search',
                headerSearchResult: '.header__searchResult',
                headerSearchContent: '.header__searchContent',
                headerSearchControl: '.header__searchControlInput',
                headerSearchData: '.js-header__searchData',
                headerPopularData: '.js-header__searchPopular',
                headerSearchOpen: '.header__searchOpen',
                headerSearchBack: '.js-header__searchBack',
                headerSearchResultElement: '.header__searchResultElement',
            };
        },
        resetSearchForm: function(component) {
            var _this = privateMethods;
            headerState.set(SEARCH_DATA, false);
            headerState.set(SEARCH_CONTENT, false);
            component
                .find(_this.selectors().headerSearchClear)
                .removeClass(_this.classes().isActive);
            component
                .find(_this.selectors().headerSearchControl)
                .val(null)
                .removeClass(_this.classes().isDirty);
            component
                .find(_this.selectors().headerSearchData)
                .addClass(_this.classes().isEmpty);
        },
        hideSearchResultsByEsc: function(component) {
            var _this = privateMethods,
                isSearchVisible = $(_this.selectors().headerSearch).is(':visible');
            this.hideByEsc(isSearchVisible, function() {
                headerState.set(SEARCH_CONTENT, false);
                component
                    .find(_this.selectors().headerSearchControl)
                    .trigger('blur');
                component
                    .find(_this.selectors().headerSearchResultElement)
                    .removeClass(_this.classes().isActive);
            });
        },
        autocompleterUtils: {
            /* Method - debounce
             * Returns a function, that, as long as it continues to be invoked, will not
             * be triggered. The function will be called after it stops being called for
             * N milliseconds. If `immediate` is passed, trigger the function on the
             * leading edge, instead of the trailing.
             */
            debounce: function(func, wait, immediate) {
                var timeout;
                return function executedFunction() {
                    var context = this,
                        args = arguments;

                    var later = function() {
                        timeout = null;
                        if (!immediate) func.apply(context, args);
                    };

                    var callNow = immediate && !timeout;

                    clearTimeout(timeout);

                    timeout = setTimeout(later, wait);

                    if (callNow) func.apply(context, args);
                };
            },
            /*
             * Method - validateEmptySpacesForInput
             * Is responsible for validates the search engine control in the header
             * so that it does not contain only spaces
             * @param  {String} expectedInputValue
             * @return {String}
             */
            validateEmptySpacesForInput: function(expectedInputValue) {
                let valueTrim = expectedInputValue.trim();
                let isSpaceLength = valueTrim.length > 0 ? false : true;
                return validateEmptySpaces = isSpaceLength;
            },
            /*
            * correctQueryValue - prepares the correct query value
            *
            * @param  {String} content of the query
            * @return {String}
            */
            correctQueryValue: function(query) {
                return query;
            },
            formValidationBeforeSubmit: function() {
                var _this = privateMethods,
                    formInput = $(_this.selectors().headerSearchControl),
                    formInputValue = formInput.val(),
                    isEmpty = null || '';

                _this.autocompleterUtils.validateEmptySpacesForInput(formInputValue);
                return formInputValue !== isEmpty && validateEmptySpaces;
            },
            validateIncorrectInputCharactersLength: function(expectedInputValue) {
                var _this = privateMethods;
                if ( expectedInputValue.length < _this.autocompleter.minQuery ) {
                    _this.autoCompleterDataEmpty();
                }
            }
        },
        renderAutocompleterResultElement: function(result, component) {
            var _this = privateMethods;
            return $('<li>', {
                class: 'header__searchResultElement',
                html: function() {
                    return $('<a>', {
                        class: 'header__searchResultAnchor',
                        href: result.url,
                        html: result.highlighting,
                        'aria-label': result.title
                    }).onHeaderEvent('click', function() {
                        _this.resetSearchForm(component);
                    });
                }
            })
        },
        renderAutocompleterData: function(data, component) {
            var _this = privateMethods;
            component.find(_this.selectors().headerSearchResultElement).removeClass(_this.classes().isActive);
            $(_this.selectors().headerSearchData)
                .empty()
                .removeClass(_this.classes().isEmpty)
                .append($('<ul>', {
                    class: 'header__searchResult list-clear',
                    html: function() {
                        return data.results.map(function(result) {
                            return _this.renderAutocompleterResultElement(result, component);
                        });
                    }
                }));
        },
        autocompleterVisibility: function() {
            var _this = privateMethods;
            headerState.on(SEARCH_CONTENT, function() {
                if ( headerState.get(SEARCH_CONTENT) ) {
                    $(_this.selectors().headerSearchContent)
                        .addClass(_this.classes().isActive);
                } else {
                    $(_this.selectors().headerSearchContent)
                        .removeClass(_this.classes().isActive);
                }
            });
        },
        autoCompleterDataEmpty: function() {
            var _this = privateMethods;
            $(_this.selectors().headerSearchData)
                .addClass(_this.classes().isEmpty)
                .empty();
        },
        closeAutocompleter: function() {
            var _this = privateMethods;
            $(document).onHeaderEvent('click', function(evt) {
                if ($(evt.target).closest(_this.selectors().headerSearchForm).length === 0 &&
                    headerState.get(SEARCH_CONTENT)) {
                    headerState.set(SEARCH_CONTENT, false);
                }
            });
        },
        removeWhiteSpacesFromValue: function(value) {
            return value.replace(/(^\s*)|(\s*$)/gi,"")
                        .replace(/[ ]{2,}/gi," ")
                        .replace(/\n /,"\n");
        },
        getSegmentFromUrl: function() {
            const pathName = window.location.pathname,
                  pathNameParts = pathName.split('/'),
                  pathNameFirst = pathNameParts[1];
            return stringUtils.isEmpty(pathNameFirst) ? defaultSegmentName : pathNameFirst;
        },
        autocompleterRequest: function(query, component) {
            var _this = privateMethods,
                inputCurrentValue = query.toLowerCase();

            // 1. Excluding the beginning and the end of whitespace
            // 2. Convert two or more spaces into one
            // 3. Exclude a newline with an initial space
            inputCurrentValue = _this.removeWhiteSpacesFromValue(inputCurrentValue);

            var inputCurrentValueNumberOfWords = inputCurrentValue.split(' ').length;

            if ( inputCurrentValueNumberOfWords > 1 ) {
                inputCurrentValue = _this.autocompleterUtils.correctQueryValue(inputCurrentValue);
            }

            headerState.set(SEARCH_QUERY, inputCurrentValue);

            if (inputCurrentValue.length < _this.autocompleter.minQuery) return;

            var autocompleterQuery = stringUtils.stringFormat("%s%s%s", _this.autocompleter.apiUrl, '?q=', encodeURI(inputCurrentValue));
            axios.get(autocompleterQuery, {
                params: {
                    size: _this.autocompleter.size,
                    segment: _this.getSegmentFromUrl()
                }
            })
            .then(function(response) {
                return response.data;
            })
            .then(function(data) {
                if (data.results && data.results.length > 0) {
                    _this.renderAutocompleterData(data, component);
                    headerState.set(SEARCH_CONTENT, true);
                    headerState.set(SEARCH_DATA, true);
                } else {
                    _this.autoCompleterDataEmpty();
                    headerState.set(SEARCH_DATA, false);
                }
            })
            .catch(function(error) {
                console.log(error);
            });
        },
        solrSearchValueState: function(valueLength, searchClear, input) {
            var _this = privateMethods;

            if ( valueLength > 0 ) {
                searchClear
                    .addClass(_this.classes().isActive);
                input
                    .addClass(_this.classes().isDirty);
            } else {
                searchClear
                    .removeClass(_this.classes().isActive);
                input
                    .removeClass(_this.classes().isDirty);
            }
        },
        solrSearch: function(component) {
            var _this = privateMethods,
                headerSearchClear = component.find(_this.selectors().headerSearchClear),
                debounceFormSearch = _this.autocompleterUtils.debounce(function(query) {
                    _this.autocompleterRequest(query, component);
                }, _this.autocompleter.debunceDelay);

            component
                .find(_this.selectors().headerSearchControl)
                .onHeaderEvent('input', function(evt) {
                    var value = evt.target.value,
                        valueLength = value.length;

                    _this.autocompleterUtils.validateEmptySpacesForInput(value);
                    _this.autocompleterUtils.validateIncorrectInputCharactersLength(value);
                    _this.solrSearchValueState(valueLength, headerSearchClear, $(this));

                    if (!validateEmptySpaces) {
                        debounceFormSearch(value);
                    }

                    if (valueLength < _this.autocompleter.minQuery) {
                        headerState.set(SEARCH_DATA, false);
                    }
                });

            var upKeyHandler = KeypressUtils.handleKey(moveUp, KeypressUtils.UPARROW),
                downKeyHandler = KeypressUtils.handleKey(moveDown, KeypressUtils.DOWNARROW),
                enterKeyHandler = KeypressUtils.handleKey(selectResult, KeypressUtils.ENTER);

            function moveUp() {
                move(-1, $(_this.selectors().headerSearchData));
                move(-1, $(_this.selectors().headerPopularData));
            }

            function moveDown() {
                move(1, $(_this.selectors().headerSearchData));
                move(1, $(_this.selectors().headerPopularData));
            }

            function selectResult() {
                var activeFocusedResult = component
                                            .find(_this.selectors().headerSearchContent)
                                            .find(_this.stateClass().isActive);
                if (activeFocusedResult.length > 0) {
                    _this.resetSearchForm(component);
                    fn.changeLocation(activeFocusedResult.children('a').attr('href'));
                    return false;
                }
            }

            function move(direction, searchType) {
                if (!searchType.is(':visible')) return;

                var _this = privateMethods,
                    childrenNumber = searchType.find('ul').children().length,
                    index = direction === 1 ? 1 : childrenNumber,
                    activeFocusedResult = searchType.find(_this.stateClass().isActive);

                if (activeFocusedResult.length) {
                    index = activeFocusedResult.prevAll().length + 1 + direction;
                    searchType
                        .find('ul')
                        .children()
                        .removeClass(_this.classes().isActive);
                }
                searchType
                    .find('ul')
                    .children(":nth-child(" + index + ")")
                    .addClass(_this.classes().isActive);
            }

            component
                .find(_this.selectors().headerSearchControl)
                .onHeaderEvent('focus', function() {
                    var _self = $(this);
                    _self.onHeaderEvent("keydown", upKeyHandler);
                    _self.onHeaderEvent("keydown", downKeyHandler);
                    _self.onHeaderEvent("keypress", enterKeyHandler);
                    headerState.set(SEARCH_CONTENT, true);
                });

            component
                .find(_this.selectors().headerSearchControl)
                .onHeaderEvent('blur', function() {
                    var _self = $(this);
                    _self.offHeaderEvent("keydown", upKeyHandler);
                    _self.offHeaderEvent("keydown", downKeyHandler);
                    _self.offHeaderEvent("keypress", enterKeyHandler);
                });

            headerSearchClear
                .onHeaderEvent('click', function() {
                    _this.resetSearchForm(component);
                    component
                        .find(_this.selectors().headerSearchControl)
                        .trigger('focus');
                });
        },
        maximumWidthForContainer: function(component) {
            var _this = privateMethods;
            component.closest(_this.selectors().layoutContainer).addClass("isFluid")
        },
        openSearchLayer: function(component) {
            var _this = privateMethods;
            component
                .find(_this.selectors().headerSearchButton)
                .onHeaderEvent('click', function() {
                    $(_this.selectors().headerSearchBox).slideDown(100);
                });
        },
        closeSearchLayer: function(component) {
            var _this = privateMethods;
            component
                .find(_this.selectors().headerSearchClose)
                .onHeaderEvent('click', function(e) {
                    e.preventDefault();
                    $(_this.selectors().headerSearchBox).slideUp(100);
                });
        },
        openMenuLayer: function(component) {
            var _this = privateMethods;
            component
                .find(_this.selectors().headerMenuButton)
                .onHeaderEvent('click', function() {
                    $(_this.selectors().headerMenuBox)
                        .addClass(_this.classes().isActive);
                    component
                        .find(".js-header__overlay")
                        .addClass(_this.classes().isActive)
                });
        },
        closeMenuLayer: function(component) {
            var _this = privateMethods;
            component
                .find(_this.selectors().headerMenuClose)
                .onHeaderEvent('click', function(e) {
                    e.preventDefault();
                    $(_this.selectors().headerMenuBox)
                        .removeClass(_this.classes().isActive);
                    component
                        .find(".js-header__overlay")
                        .removeClass(_this.classes().isActive);
                });
        },
        setCssVariable: function (variableColor) {
            const root = document.querySelector(':root');
            return root.style.setProperty('--color-headerColor', 'var('+ variableColor +')');
        },
        whiteVariantHeader: function(component) {
            if(component.hasClass( "header--vWhite")) {
                privateMethods.setCssVariable('--color-ingWhite');
            } else {
                privateMethods.setCssVariable('--color-ingBlack');
            }
        },
        redirectQuery: function(query) {
            var nodeWithSearchParam = '/wyniki-wyszukiwania?q=';
            return nodeWithSearchParam.concat(query);
        },
        searchEnginech: function(component) {
            let _this = privateMethods,
                headerSearchClear = component.find(_this.selectors().headerSearchClear),
                headerSerachInput = component.find(_this.selectors().headerSearchControlInput);
            component
                .find(_this.selectors().headerSearchForm)
                .onHeaderEvent('submit', function(evt) {
                    submitForm(evt);
                });
            component
                .find(_this.selectors().headerSearchControlInput)
                .onHeaderEvent("keypress", KeypressUtils.handleKey(submitForm, KeypressUtils.ENTER));
            component
                .find(_this.selectors().headerSearchClear)
                .onHeaderEvent("click", function(){
                    headerSerachInput.val();
                });
            function submitForm(e) {
                let _this = privateMethods,
                    headerSerachInput = component.find(_this.selectors().headerSearchControlInput);
                let headerSerachInputValue = headerSerachInput.val();
                e.preventDefault();
                if( headerSerachInputValue.length !== 0 ) {
                    fn.changeLocation(_this.redirectQuery(encodeURI(headerSerachInputValue)));
                }
            }

        },
        stickyHeaderOnCondition: function(w, doc) {
            let currentScroll = this.getFromState('currentScroll'),
                prevScroll = this.getFromState('prevScroll');
            this.updateState('currentScroll', w.scrollY || doc.scrollTop);
            if (currentScroll > prevScroll) {
                this.updateState('direction', 'down');
            } else if (currentScroll < prevScroll) {
                this.updateState('direction', 'up');
            }
            this.stickyHeaderVisibility();
            this.updateState('prevScroll', currentScroll);
        },
        stickyHeaderVisibility: function() {
            const header = $('.header'),
                  headerHeight = header.outerHeight(true, true),
                  _this = privateMethods;
            let currentScroll = this.getFromState('currentScroll'),
                direction = this.getFromState('direction');
                header
                    .find(_this.selectors().headerSearchControlInput)
                    .offHeaderEvent('blur')
                    .trigger('blur');
            if (direction === 'up') {
                if (currentScroll < headerHeight) {
                    header.removeClass('isScrolledUp');
                    if(header.hasClass( "header--vWhite")) {
                        privateMethods.setCssVariable('--color-ingWhite');
                    }
                } else {
                    header.addClass('isScrolledUp');
                    privateMethods.setCssVariable('--color-ingBlack');
                }
                this.updateState('prevDirection', direction);

            } else if (direction === 'down' ) {
                header.removeClass('isScrolledUp');
                this.updateState('prevDirection', direction);
            }
        },
        stickyHeader: function() {
            const doc = document.documentElement,
                w = window;
            const _this = privateMethods;
            $(window).on("scroll", function(){
                _this.stickyHeaderOnCondition(w, doc);
                headerState.set(SEARCH_CONTENT, false);
            });
        },
        initHeader: function(component) {
            return {
                maximumWidthForContainer: this.maximumWidthForContainer(component),
                searchEnginech: this.searchEnginech(component),
                openSearchLayer: this.openSearchLayer(component),
                closeSearchLayer: this.closeSearchLayer(component),
                openMenuLayer: this.openMenuLayer(component),
                closeMenuLayer: this.closeMenuLayer(component),
                whiteVariantHeader: this.whiteVariantHeader(component),
                stickyHeader: this.stickyHeader(),
                solrSearch: this.solrSearch(component),
                autocompleterVisibility: this.autocompleterVisibility(),
                closeAutocompleter: this.closeAutocompleter()
            }
        }
    }
    return {
        init: function(componentId, $componentElement) {
            var _this = privateMethods;
            _this.initHeader($componentElement);
        }
    }
});
